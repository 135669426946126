<template>
  <v-row class="props-list">
    <v-col md="2">Альфа-код</v-col>
    <v-col md="10">{{ country.alpha2Code }}</v-col>

    <v-col md="2">Название EN</v-col>
    <v-col md="10">
      <v-text-field :value="country.nameEn" dense @input="setField('nameEn', $event)" />
    </v-col>

    <v-col md="2">Название RU</v-col>
    <v-col md="10">
      <v-text-field :value="country.name" dense @input="setField('name', $event)" />
    </v-col>

    <v-col md="2">Вес</v-col>
    <v-col md="10">
      <v-text-field :value="country.weight" dense @input="setField('weight', $event)" />
    </v-col>

    <v-col class="font-weight-regular" md="12">
      <v-checkbox v-model="isVisibleProxy" class="my-0" label="Страна показана в списке" hide-details />
    </v-col>
  </v-row>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

export default {
  mixins: [editorFields],
  props: {
    country: {
      type: Object,
      required: true
    }
  },
  computed: {
    isVisibleProxy: {
      get() {
        return this.country.isVisible;
      },
      set(value) {
        this.setField("isVisible", value);
      }
    }
  }
};
</script>
