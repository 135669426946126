<template>
  <div>
    <btn-back class="mb-4" :to="{ name: 'countries-index' }" />

    <template v-if="country">
      <h2 class="mb-4">Страна {{ country.alpha2Code }}</h2>

      <v-sheet elevation="1">
        <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
          <v-tab>Обзор</v-tab>

          <v-tab-item class="pa-3">
            <country-summary :country="country" @change-field="changeField" />

            <div class="text-right">
              <v-btn color="accent" :loading="updating" @click="updateCountry">Сохранить</v-btn>
            </div>
          </v-tab-item>
        </v-tabs>
      </v-sheet>
    </template>

    <h2 v-else-if="!loading" class="mb-4">Страна по коду "{{ code }}" не найдена</h2>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";
import { UpdateCountryDto } from "~/libs/country/update-country-dto";

import CountrySummary from "~/components/countries/_code/summary";

export default {
  mixins: [editorForm],
  data() {
    return {
      country: null,

      loading: true,
      updating: false
    };
  },
  created() {
    this.fetchCountry();
  },
  computed: {
    code() {
      return this.$route.params.code;
    }
  },
  methods: {
    async fetchCountry() {
      this.loading = true;

      try {
        this.country = await this.$axios.$get(`countries/${this.code}`);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error(`Не получена информация по коду страны: "${this.code}"` + html);
      } finally {
        this.loading = false;
      }
    },
    async updateCountry() {
      this.updating = true;

      try {
        this.country = await this.$axios.$patch(
          `countries/${this.code}`,
          JSON.stringify(new UpdateCountryDto(this.country)),
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );

        this.$snackbar.success("Обновлено");
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не обновлено" + html);
      } finally {
        this.updating = false;
      }
    },
    changeField(payload) {
      this.commitChange(this.country, payload);
    }
  },
  components: {
    CountrySummary
  }
};
</script>
